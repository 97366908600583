import { StorageService } from './storage';

/**
 * Service class for query param.
 */
export function QueryParamService() {
  const storageService = new StorageService();

  /**
   * Handle the provided query param by determining if it is present and stores it in localstorage if available
   *
   * @param {string} queryParam     The query param to be retrieved from URL
   * @param {string} attributeName  The attribute name
   */
  function saveQueryParam(queryParam, attributeName) {
    let attributeValue = null;

    if (!(attributeValue = getQueryParam(queryParam))) {
      return;
    }

    // Store param in the local storage
    storageService.localStorage.set('ngStorage-' + attributeName, attributeValue);
  }

  /**
   * Returns a given query param retrieved from the URL
   *
   * @param {string} param Param name to be retrieved from the URL
   *
   * @returns {string} Returns the value from the URL. NULL if param not exists
   */
  function getQueryParam(param) {
    try {
      const url = new URL(window.location.href);
      return url.searchParams.get(param);
    } catch (error) {
      console.error(error);
      return getParameterByNameUsingRegex(param);
    }
  }

  /**
   * Gets the value of a query parameter from the current url using a regular expression
   *
   * @param {string} param Param name to be retrieved from the URL
   *
   * @returns {string|null} Query param value from the url, null if doesn't exist
   */
  function getParameterByNameUsingRegex(param) {
    param = param.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + param + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(window.location.href);
    if (!results) {
      return null;
    }

    if (!results[2]) {
      return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  return {
    saveQueryParam,
    getQueryParam,
    getParameterByNameUsingRegex,
  };
}
